import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    addCountryTools,
    getCountries,
    setEditCountry,
    updateCountryTools
} from '../actions/toolsActions';
import CreateModalContainer from "../CreateModalContainer";
import AutoTable from "../../components/common/AutoTable";
import styled from "styled-components";
import MatBox from "../../components/common/Mat/MatBox";
import StyledIcon from "../../components/common/StyledIcon";
import MatButton from "../../components/common/Mat/MatButton";
import countriesTableModal from "../countriesTableModal";

function ToolsCountry({addCountryToolsData, updateCountryToolsData, getCountriesData, countryEdit, countries, setEditCountryData}) {
    const [showNameModal, setShowNameModal] = useState(false);
    const [countryName, setCountryName] = useState("");

    useEffect(() => {
        getCountriesData();
    },[])

    useEffect(() => {
        if(countries.find(el => el._id == countryEdit)) {
            setCountryName(countries.find(el => el._id == countryEdit).title);
        }
    }, [countryEdit]);
    const updateCountryName = (value) => {
        setCountryName(value.toUpperCase());
    }

    const ResultsBox = styled(MatBox)`
    padding: 0;
    padding-top: 25px;
    strong {
        margin: 15px;
        font-size: 17px;
    }
    `;

    return (
        <div>
            <ResultsBox>
                <strong>Countries {countryEdit}</strong>
                <MatButton success withIcon style={{float: "right", marginRight: 20}}
                           onClick={() => setShowNameModal(!showNameModal)}>
                    Add new country<StyledIcon glyph="plus" />
                </MatButton>
                <AutoTable
                    model={countriesTableModal}
                />
            </ResultsBox>
            <CreateModalContainer
                showNameModal={showNameModal}
                closeModal={() => setShowNameModal(false)}
                onSave={() => addCountryToolsData(countryName)}
                name={countryName}
                setName={updateCountryName}
                title="country"
                icon="flag"
            />
        {/*    modal for editing country */}
            <CreateModalContainer
                showNameModal={countryEdit}
                closeModal={() => setEditCountryData(null)}
                onSave={() => updateCountryToolsData(countryName)}
                name={countryName}
                setName={updateCountryName}
                title="country"
                icon="flag"
            />
        </div>
    );
}

const mapStateToProps = ({toolsReducer}) => {
    return {
        ...toolsReducer
    }
}

function mapDispatchToProps() {
    return dispatch => ({
        addCountryToolsData: (countryName) => dispatch(addCountryTools(countryName)),
        updateCountryToolsData: (countryName) => dispatch(updateCountryTools(countryName)),
        getCountriesData: () => dispatch(getCountries()),
        setEditCountryData: (value) => dispatch(setEditCountry(value))
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolsCountry);
