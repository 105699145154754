import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    REPORTS_CHANGED,
    UNAPPROVED_COUNTRY_CHANGED
} from "../actions/unapprovedReportsTableActions";

export const makeInitialState = _ => ({
    error: null,
    state: STATE_DEFAULT,
    reports: [],
    country: null
});

export default function unapprovedReportsTable(state = makeInitialState(), action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case REPORTS_CHANGED:
            return {
                ...state,
                reports: action.payload
            };
        case UNAPPROVED_COUNTRY_CHANGED:
            return {
                ...state,
                country: action.payload
            }
        default:
            return state;
    }
}
