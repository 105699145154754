import {
    STATE_DEFAULT,
    STATE_CHANGED,
    ERROR_CHANGED,
    REPORTS_CHANGED,
    MODAL_DATE_END_CHANGED,
    MODAL_DATE_START_CHANGED,
    SHOW_GENERATE_REPORT_MODAL_CHANGED,
    MODAL_EMPLOYEE_CHANGED,
    MODAL_EMPLOYER_CHANGED,
    MODAL_COMPANY_CHANGED,
    MODAL_ORDER_CHANGED,
    MODAL_CUSTOMER_CHANGED,
    MODAL_LOCATION_CHANGED,
    MODAL_COUNTRY_CHANGED,
    TIME_RANGE_CHANGED,
    APPROVED_COUNTRY_CHANGED
} from "../actions/approvedReportsTableActions";

export const makeInitialState = _ => ({
    error: null,
    state: STATE_DEFAULT,
    reports: [],
    modalDateStart: null,
    modalDateEnd: null,
    modalEmployee: null,
    modalEmployer: null,
    modalCompany: null,
    modalOrder: null,
    modalCustomer: null,
    modalLocation: null,
    modalCountry: null,
    showGenerateReportModal: false,
    week: null,
    year: null,
    country: null
});

export default function approvedReportsTable(state = makeInitialState(), action) {
    switch (action.type) {
        case STATE_CHANGED:
            return {
                ...state,
                state: action.payload
            };
        case ERROR_CHANGED:
            return {
                ...state,
                error: action.payload
            };
        case REPORTS_CHANGED:
            return {
                ...state,
                reports: action.payload
            };
        case MODAL_DATE_END_CHANGED:
            return {
                ...state,
                modalDateEnd: action.payload
            };
        case MODAL_DATE_START_CHANGED:
            return {
                ...state,
                modalDateStart: action.payload
            };
        case SHOW_GENERATE_REPORT_MODAL_CHANGED:
            return {
                ...state,
                showGenerateReportModal: action.payload
            };
        case MODAL_EMPLOYEE_CHANGED:
            return {
                ...state,
                modalEmployee: action.payload
            };
        case MODAL_EMPLOYER_CHANGED:
            return {
                ...state,
                modalEmployer: action.payload
            };
        case MODAL_COMPANY_CHANGED:
            return {
                ...state,
                modalCompany: action.payload
            };
        case MODAL_ORDER_CHANGED:
            return {
                ...state,
                modalOrder: action.payload
            };
        case MODAL_CUSTOMER_CHANGED:
            return {
                ...state,
                modalCustomer: action.payload
            };
        case MODAL_LOCATION_CHANGED:
            return {
                ...state,
                modalLocation: action.payload
            };
        case MODAL_COUNTRY_CHANGED:
            return {
                ...state,
                modalCountry: action.payload
            };
        case TIME_RANGE_CHANGED:
            return {
                ...state,
                week: action.payload.week,
                year: action.payload.year
            };
        case APPROVED_COUNTRY_CHANGED:
            return {
                ...state,
                country: action.payload
            };
        default:
            return state;
    }
}
