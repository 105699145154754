import { INA_STATE_TAKEN } from "../actions/orderNumberActions";
import {
    validationErrors,
    VERROR_INTERNAL_ORDER_NUMBER_TAKEN,
    VERROR_NO_UNOFFICIAL_ORDER_NAME,
    VERROR_NO_OFFICIAL_ORDER_NAME,
    VERROR_NO_CALENDAR_SELECT,
    VERROR_NO_SCENARIO_SELECT,
    VERROR_NO_COUNTRY_SELECT
} from "../validationErrors";

export default function getValidationErrors(state) {
    let errors = [];
    if(!state.orders.calendar.calendar) {
        errors.push(validationErrors[VERROR_NO_CALENDAR_SELECT]())
    }
    if(!state.orders.scenario.scenario) {
        errors.push(validationErrors[VERROR_NO_SCENARIO_SELECT]())
    }
    if (state.orders.orderNumbers.inaState === INA_STATE_TAKEN) {
        errors.push(validationErrors[VERROR_INTERNAL_ORDER_NUMBER_TAKEN]());
    }
    if (!state.orders.unofficialName.unofficialName.trim()) {
        errors.push(validationErrors[VERROR_NO_UNOFFICIAL_ORDER_NAME]());
    }
    if (!state.orders.officialName.officialName.trim()) {
        errors.push(validationErrors[VERROR_NO_OFFICIAL_ORDER_NAME]());
    }
    if(!state.orders.country.countryId) {
        errors.push(validationErrors[VERROR_NO_COUNTRY_SELECT]());
    }
    return errors;
}
