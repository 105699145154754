import {makeActionCreator} from "../../utils";
import {apiFetch} from "../../actions/apiActions";
import {userChanged} from "../../actions/authActions";

export const ORDER_COUNTRY_CHANGED = "ORDERS.ORDER_NUMBER.ORDER_COUNTRY_CHANGED";

export const countryChanged = makeActionCreator(ORDER_COUNTRY_CHANGED, "payload");

export function lastUsedCountryChange(country) {
    return (dispatch, getState) => {
        const {auth} = getState();
        dispatch(userChanged({...auth.user, lastUsedCountry: country}))
        dispatch(apiFetch(`/api/users/${auth.user._id}/lastUsedCountry`, {
            method: "PATCH",
            body: JSON.stringify({
                lastUsedCountry: country
            })
        }))
    };
}