import { combineReducers } from "redux";
import customers from "./customers";
import files from "./files";
import officialName from "./officialName";
import order from "./order";
import orderNumbers from "./orderNumbers";
import orderParts from "./orderParts";
import problemDescription from "./problemDescription";
import unofficialName from "./unofficialName";
import validation from "./validation";
import calendar from "./calendar";
import scenario from "./scenario";
import country from "./country";

export default combineReducers({
    orderNumbers,
    customers,
    orderParts,
    order,
    problemDescription,
    unofficialName,
    files,
    officialName,
    validation,
    calendar,
    scenario,
    country
});
