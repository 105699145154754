import { ORDER_DATA_CHANGED, ORDER_RESET } from "../actions/orderActions";
import {ORDER_COUNTRY_CHANGED} from "../actions/countryActions";

const makeInitialState = _ => ({
    countryId: null
});

export default function country(state = makeInitialState(), action) {
    switch (action.type) {
        case ORDER_DATA_CHANGED:
            return state;
            // return action.payload.orderCountryId;
        case ORDER_RESET:
            return makeInitialState();
        case ORDER_COUNTRY_CHANGED:
            return {
                ...state,
                countryId: action.payload
            }
        default:
            return state;
    }
}
