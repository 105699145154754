import React from "react";
import { connect } from "react-redux";
import SidebarMenu from "../../components/common/SidebarMenu";
import {
    PERMISSION_VIEW_USERS,
    PERMISSION_VIEW_COMPANIES,
    PERMISSION_VIEW_ORDERS,
    PERMISSION_CREATE_REPORTS,
    PERMISSION_VIEW_REPORTS_LIST,
    PERMISSION_VIEW_EMPLOYEES,
    PERMISSION_QUICK_ORDERS_SEARCHING,
    PERMISSION_QUICK_REPORTS_SEARCHING, PERMISSION_VIEW_CALENDARS_CONFIGURATOR, PERMISSION_CREATE_COUNTRIES
} from '../../../../common/src/permissions';
import { userHasPermissionSelector } from "../../selectors/authSelectors";
import { showSidebarMenuChanged } from "../../actions/dashboardUIActions";

@connect(
    state => ({
        sidebarExpanded: state.dashboardUI.showSidebarMenu,
        items: [
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_COMPANIES),
                key: "companies",
                label: "Companies",
                to: "/dashboard/companies/list",
                glyph: "envelope"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_QUICK_ORDERS_SEARCHING),
                key: "orders",
                label: "Orders",
                to: "/dashboard/orders/list",
                glyph: "inbox"
            },
            {
                show:
                    userHasPermissionSelector(state, PERMISSION_VIEW_ORDERS) &&
                    !userHasPermissionSelector(state, PERMISSION_QUICK_ORDERS_SEARCHING),
                key: "orders-simple",
                label: "Orders",
                to: "/dashboard/orders",
                glyph: "inbox"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_QUICK_REPORTS_SEARCHING),
                key: "reports",
                label: "Report",
                to: "/dashboard/reports/list",
                glyph: "file"
            },
            {
                show:
                    userHasPermissionSelector(state, PERMISSION_CREATE_REPORTS) &&
                    !userHasPermissionSelector(state, PERMISSION_QUICK_REPORTS_SEARCHING),
                key: "reports-simple",
                label: "Report",
                to: "/dashboard/reports",
                glyph: "file"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_REPORTS_LIST),
                key: "office",
                label: "Office",
                to: "/dashboard/office/unapproved",
                glyph: "th-list"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_USERS),
                key: "users",
                label: "Users",
                to: "/dashboard/users/list",
                glyph: "user"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_EMPLOYEES),
                key: "hr",
                label: "HR",
                to: "/dashboard/hr",
                glyph: "list-alt"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_CALENDARS_CONFIGURATOR),
                key: "calendar",
                label: "Calendars",
                to: "/dashboard/calendar",
                glyph: "calendar"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_VIEW_CALENDARS_CONFIGURATOR),
                key: "scenario",
                label: "Scenarios",
                to: "/dashboard/scenario",
                glyph: "sort-by-attributes-alt"
            },
            {
                show: userHasPermissionSelector(state, PERMISSION_CREATE_COUNTRIES),
                key: "country",
                label: "Country",
                to: "/dashboard/country",
                glyph: "flag"
            }
        ]
    }),
    dispatch => ({
        hideSidebarMenu: () => dispatch(showSidebarMenuChanged(false))
    }),
    null,
    {
        pure: true,
        areStatesEqual: (next, prev) => {
            return next.auth === prev.auth && next.dashboardUI.showSidebarMenu === prev.dashboardUI.showSidebarMenu;
        }
    }
)
export default class DashboardSidebar extends React.Component {
    render() {
        return (
            <SidebarMenu
                expanded={this.props.sidebarExpanded}
                items={this.props.items}
                onItemClicked={this.props.hideSidebarMenu}
            />
        );
    }
}
