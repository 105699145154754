import React, {useState, useEffect} from 'react';
import { ControlLabel, FormControl, FormGroup, Panel } from 'react-bootstrap';
import LoadableButton from '../../components/common/LoadableButton';
import { connect } from 'react-redux';
import {clearStatusInConfirm2FA, confirm2FADuringPasswordChanging} from '../actions/twoFAActions';
import TwoFAConfirmCodeModal from "../../components/common/TwoFAConfirmCodeModal";

function ChangePassword(props) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [secretIsSafty, setSecretIsSafty] = useState('error');
  const [secretIsInvalid, setSecretIsInvalid] = useState('error');
  const [showModal2FA, setShowModal2FA] = useState(false);
  const [twoFACode, setTwoFACode] = useState('');
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if(props.status && props.status.success) {
      setPassword('');
      setNewPassword('');
      setNewPassword2('');
    }
  }, [props]);

  useEffect(() => {
    if(props.status && props.status.value) {
      setShowLoading(false);
    }
  });
  const handleChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  }

  const handleChangeNewPassword = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
    const newPasswordValue = e.target.value;
    const newPasswordRegex = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?!.*\s).{8,64})/g);
    if(newPasswordRegex.test(newPasswordValue)) {
      setSecretIsSafty("")
    } else {
      setSecretIsSafty("error")
    }
    newPasswordValue !== newPassword2 ? setSecretIsInvalid('error') : setSecretIsInvalid('');
  }

  const handleChangeNewPassword2 = (e) => {
    e.preventDefault();
    setNewPassword2(e.target.value);
    const newPassword2Value = e.target.value;
    const newPassword2Regex = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?!.*\s).{8,64})/g);
    (newPassword !== e.target.value || !newPassword2Regex.test(newPassword2Value) )? setSecretIsInvalid('error') : setSecretIsInvalid('');
  }

  const handleConfirm2FACode = (twoFACode) => {
    props.confirm2FADuringPasswordChanging({code: twoFACode, password, newPassword, newPassword2});
    setShowLoading(true);
    setTwoFACode("");
  }

  const handleClose = () => {
    setShowModal2FA(false);
    props.clearStatusInConfirm2FAChanging()
  }
  return (<div style={{width: 400, margin: 'auto'}}>

    <h3>Change password</h3>
    {props.status && !props.status.success && (<Panel header="Error" bsStyle="danger">
      {props.status.message}
    </Panel>)}
    <FormGroup>
      <FormControl
        type="password"
        placeholder="Enter current password"
        value={password}
        onChange={handleChangePassword}
      />
    </FormGroup>
    <FormGroup validationState={secretIsSafty}>
      {secretIsSafty === 'error' && (<ControlLabel>
        Password must have minimum one special char, one digit, one small and one big letter. Minimum length is 8 characters
      </ControlLabel>)}
      <FormControl
        type="password"
        placeholder="New password"
        value={newPassword}
        onChange={handleChangeNewPassword}
      />
    </FormGroup>
    <FormGroup validationState={secretIsInvalid}>
      {secretIsInvalid === 'error' && (<ControlLabel>
      Password must be the same as new password.
    </ControlLabel>)}
      <FormControl
        type="password"
        placeholder="Repeat new password"
        value={newPassword2}
        onChange={handleChangeNewPassword2}
      />
    </FormGroup>

    <LoadableButton
      disabled={!password || !newPassword || newPassword !== newPassword2 || secretIsSafty==='error'}
      block
      bsStyle="primary"
      isLoading={isLoading}
      onClick={()=>setShowModal2FA(true)}
    >
      Update new password
    </LoadableButton>
    <TwoFAConfirmCodeModal show={showModal2FA}
                           onClosed={()=> handleClose()}
                           modalConfirmCodeUpdate={(ev)=>setTwoFACode(ev.target.value)}
                           onConfirm={() => handleConfirm2FACode(twoFACode)}
                           title={"Confirm decision with 2FA code"}
                           confirmCode={twoFACode}
                           showLoading={showLoading}
                           status={props.status}
    />
  </div>);
}

function mapStateToProps({changePasswordReducer, twoFAReducer}) {
  return {
    ...changePasswordReducer,
    ...twoFAReducer
  }
}

function mapDispatchToProps() {
  return dispatch => ({
    confirm2FADuringPasswordChanging: (code) => dispatch(confirm2FADuringPasswordChanging(code)),
    clearStatusInConfirm2FAChanging: () => dispatch(clearStatusInConfirm2FA())
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
