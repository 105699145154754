import React from "react";
import { Glyphicon } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { logoutRequested, STATE_LOADING, STATE_LOGGED_IN } from "../../actions/authActions";
import AnimatedHider from "../../components/common/AnimatedHider";
import StyledIcon from "../../components/common/StyledIcon";
import {
    ButtonIcon,
    LineSeperator,
    NavbarButton,
    NavbarItemsRight,
    NavbarDropdownContent,
    UserMenuListItem,
    UserMenuListItemIcon,
    UserNameContainer
} from "../../components/navbarStyles";
import { push } from 'react-router-redux';

@connect(
    ({ auth }) => auth,
    null,
    null,
    {
        areStatesEqual: (nextState, prevState) => {
            prevState.auth === nextState.auth;
        },
        pure: true
    }
)

@connect(
  null,
  dispatch => ({
      push: p => dispatch(push(p)),
  }),
)

export default class UserDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showUserMenu: false };
    }
    showUserMenu = () => {
        this.setState({ showUserMenu: true });
    };

    hideUserMenu = () => {
        if (this.state.showUserMenu) {
            this.setState({ showUserMenu: false });
        }
    };
    getShortDisplayName() {
        let nameSplit = this.props.user.displayName.split(" ");
        if (nameSplit.length >= 2) {
            return nameSplit[1];
        }
        return this.props.user.displayName;
    }
    requestLogout = () => {
        this.props.dispatch(logoutRequested());
    };
    goToChangePasswordPage = _ => {
        this.props.push({ pathname: "/dashboard/change-password"});
    };
    goTo2FAPage = _ => {
        this.props.push({ pathname: "/dashboard/2fa"});
    };

    render() {
        if (this.props.authState === STATE_LOADING) {
            return <StyledIcon spin className={this.props.className} />;
        } else if (this.props.authState === STATE_LOGGED_IN) {
            return (
                <div>
                    <NavbarButton onClick={this.showUserMenu}>
                        <ButtonIcon>
                            <Glyphicon glyph="user" />
                            <UserNameContainer
                                hideOnMobile
                            >{`Hello, ${this.getShortDisplayName()}!`}</UserNameContainer>
                        </ButtonIcon>
                    </NavbarButton>

                    <OutsideClickHandler onOutsideClick={this.hideUserMenu} disabled={!this.state.showUserMenu}>
                        <AnimatedHider show={this.state.showUserMenu}>
                            <NavbarDropdownContent responsiveOffset>
                                <LineSeperator />
                                <UserMenuListItem onClick={this.goToChangePasswordPage}>
                                    <UserMenuListItemIcon glyph="cog" />
                                    Change password
                                </UserMenuListItem>
                                <LineSeperator />
                                <UserMenuListItem onClick={this.goTo2FAPage}>
                                    <UserMenuListItemIcon glyph="wrench" />
                                    2FA
                                </UserMenuListItem>
                                <LineSeperator />
                                <UserMenuListItem onClick={this.requestLogout}>
                                    <UserMenuListItemIcon glyph="log-out" />
                                    Logout
                                </UserMenuListItem>
                                <LineSeperator />
                            </NavbarDropdownContent>
                        </AnimatedHider>
                    </OutsideClickHandler>
                </div>
            );
        } else {
            return [];
        }
    }
}
