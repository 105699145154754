import moment from "moment";
import React from "react";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import { apiFetch } from "../../actions/apiActions";
import { showPromptModal, showConfirmModal } from "../../actions/modalsActions";
import { encodeParams, formatDate, makeActionCreator } from "../../utils";
import getValidationErrors from "../selectors/getValidationErrors";
import { companySelected, loadCustomerDetails, customerAdded } from "./customersActions";
import { errorsChanged } from "./validationActions";
import { parseRawQueryString } from "../../urlUtils";
import { getAllTimeTools } from '../../Tools/actions/toolsActions';
import {orderCalendarChanged} from "./calendarActions";
import {orderScenarioChanged} from "./scenarioActions";
import {countryChanged} from "./countryActions";
import {authCheckRequested} from "../../actions/authActions";

export const STATE_NEW = "STATE_NEW";
export const STATE_LOADED = "STATE_LOADED";
export const STATE_OLD_VERSION_LOADED = "STATE_OLD_VERSION_LOADED";
export const STATE_LOADING = "STATE_LOADING";
export const STATE_ERROR = "STATE_ERROR";

export const STATE_CHANGED = "ORDERS.ORDER.STATE_CHANGED";
export const ERROR_CHANGED = "ORDERS.ORDER.ERROR_CHANGED";
export const ORDER_DATA_CHANGED = "ORDERS.ORDER.ORDER_DATA_CHANGED";
export const ORDER_RESET = "ORDERS.ORDER.ORDER_RESET";
export const ORDER_VERSIONS_CHANGED = "ORDERS.ORDER.ORDER_VERSIONS_CHANGED";
export const VIEWED_VERSION_CHANGED = "ORDERS.ORDER.VIEWED_VERSION_CHANGED";
export const SHOW_VERSION_MESSAGE_MODAL_CHANGED = "ORDERS.ORDER.SHOW_VERSION_MESSAGE_MODAL_CHANGED";
export const MODAL_VERSION_MESSAGE_CHANGED = "ORDERS.ORDER.MODAL_VERSION_MESSAGE_CHANGED";
export const FINISHED_CHANGED = "ORDERS.ORDER.FINISHED_CHANGED";

export const stateChanged = makeActionCreator(STATE_CHANGED, "payload");
export const errorChanged = makeActionCreator(ERROR_CHANGED, "payload");
export const orderDataChanged = makeActionCreator(ORDER_DATA_CHANGED, "payload");
export const orderReset = makeActionCreator(ORDER_RESET);
export const orderVersionsChanged = makeActionCreator(ORDER_VERSIONS_CHANGED, "payload");
export const viewedVersionChanged = makeActionCreator(VIEWED_VERSION_CHANGED, "payload");
export const showVersionMessageModalChanged = makeActionCreator(SHOW_VERSION_MESSAGE_MODAL_CHANGED, "payload");
export const modalVersionMessageChanged = makeActionCreator(MODAL_VERSION_MESSAGE_CHANGED, "payload");
export const finishedChanged = makeActionCreator(FINISHED_CHANGED, "payload");

export function ordersSearched(query, { location, customer, hideFinished = false, limit = -1, forceId = null } = {}) {
    return async dispatch => {
        try {
            let u = encodeParams`/api/orders/search?query=${query}&hideFinished=${hideFinished}`;
            if (location) {
                u += encodeParams`&location=${location}`;
            }
            if (customer) {
                u += encodeParams`&customer=${customer}`;
            }

            if (limit != -1) {
                u += encodeParams`&limit=${limit}`;
            }

            if (forceId) {
                u += encodeParams`&forceId=${forceId}`;
            }

            let resp = await dispatch(apiFetch(u));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            return data;
        } catch (e) {
            console.error("Error while searching orders", e);
            toast("Failed to load orders search: " + e.message, { type: "error" });
            return [];
        }
    };
}

export function orderLoadRequested(id) {
    return async (dispatch, getState) => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/orders/${id}`));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            dispatch(orderDataChanged(data));
            dispatch(orderVersionsChanged(data.versions));
            dispatch(orderCalendarChanged(data.calendar));
            dispatch(orderScenarioChanged(data.scenario));
            dispatch(countryChanged(data.country));
            await dispatch(loadCustomerDetails(0));
            await dispatch(loadCustomerDetails(1));
            dispatch(stateChanged(STATE_LOADED));
            await dispatch(getAllTimeTools());
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function orderVersionLoadRequested(id, versionId) {
    return async dispatch => {
        dispatch(stateChanged(STATE_LOADING));
        try {
            let resp = await dispatch(apiFetch(encodeParams`/api/orders/${id}/version/${versionId}`));
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            dispatch(orderDataChanged(data));
            dispatch(orderVersionsChanged(data.versions));
            await dispatch(loadCustomerDetails(0));
            await dispatch(loadCustomerDetails(1));
            dispatch(stateChanged(STATE_OLD_VERSION_LOADED));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function orderSaved() {
    return async (dispatch, getState) => {
        const errors = getValidationErrors(getState());
        dispatch(errorsChanged(errors));
        if (errors.length > 0) {
            toast(
                <div>
                    This order contains the following errors:
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e.message}</li>
                        ))}
                    </ul>
                </div>,
                {
                    type: "error",
                    autoClose: errors.length * 5000
                }
            );
            return;
        }
        let { orders, auth } = getState();
        if (!orders.customers.customers[1] || !orders.customers.customers[1].companyId) {
            const modalResult = await dispatch(
                showConfirmModal({
                    title: "No location selected.",
                    message: "You can copy it from the customer or cancel.",
                    okButtonText: "Copy and save",
                    cancelButtonText: "Cancel"
                })
            );
            if (!modalResult) {
                return;
            }
            if (!orders.customers.customers[0] || !orders.customers.customers[0].companyId) {
                toast("Failed to copy location from customer! No customer selected.", { type: "error" });
                return;
            }
            if (orders.customers.customers.length <= 1) {
                dispatch(customerAdded());
            }
            await dispatch(companySelected(1, orders.customers.customers[0].companyId));
            await dispatch(loadCustomerDetails(1));
        }
        try {
            let { orders, scenariosReducer, toolsReducer } = getState();
            if (orders.order.state === STATE_NEW) {
                dispatch(stateChanged(STATE_LOADING));
                let resp = await dispatch(
                    apiFetch("/api/orders", {
                        method: "POST",
                        body: JSON.stringify({
                            orderNumbers: orders.orderNumbers,
                            orderParts: orders.orderParts,
                            problemDescription: orders.problemDescription,
                            unofficialName: orders.unofficialName,
                            officialName: orders.officialName,
                            customers: {
                                customer: orders.customers.customers[0] || null,
                                location: orders.customers.customers[1] || null
                            },
                            files: orders.files,
                            calendarId: orders.calendar.calendar.id,
                            scenarioId: orders.scenario.scenario.id,
                            countryId: orders.country.countryId
                        })
                    })
                );
                let data = await resp.json();
                if (resp.status !== 200) {
                    throw new Error(data.message || "Unknown error");
                }
                await dispatch(apiFetch(`/api/users/${auth.user._id}/lastUsedCountry`, {
                    method: "PATCH",
                    body: JSON.stringify({
                        lastUsedCountry: orders.country.countryId
                    })
                }))
                dispatch(stateChanged(STATE_LOADED));
                toast("Order created successfully!", { type: "success" });

                if (parseRawQueryString(getState().router.location.search).addToDesktop) {
                    const desktopNumber = parseRawQueryString(getState().router.location.search).desktopNumber;
                    let resp = await dispatch(
                        apiFetch(encodeParams`/api/desktops/${desktopNumber}/orders`, {
                            method: "POST",
                            body: JSON.stringify({
                                orderId: data._id
                            })
                        })
                    );
                    let dataFromDesktops = await resp.json();
                    if (resp.status !== 200) {
                        throw new Error(
                            "Failed to add order to desktops: " + (dataFromDesktops.message || "Unknown error")
                        );
                    }
                    dispatch(push(encodeParams`/dashboard/desktops?forceDesktopNumber=${desktopNumber}`));
                } else {
                    dispatch(push(encodeParams`/dashboard/orders/${data._id}`));
                }
                await dispatch(authCheckRequested());
            } else if (orders.order.state === STATE_LOADED) {
                dispatch(modalVersionMessageChanged(""));
                dispatch(showVersionMessageModalChanged(true));
            } else {
                throw new Error("Not implemented!");
            }
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function newOrderVersionCreated(extraData = {}) {
    return async (dispatch, getState) => {
        try {
            let orders = getState().orders;
            dispatch(stateChanged(STATE_LOADING));
            dispatch(showVersionMessageModalChanged(false));
            let resp = await dispatch(
                apiFetch(encodeParams`/api/orders/${orders.order.orderId}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        versionMetadata: {
                            message: orders.order.modalVersionMessage
                        },
                        orderParts: orders.orderParts,
                        problemDescription: orders.problemDescription,
                        unofficialName: orders.unofficialName,
                        officialName: orders.officialName,
                        customers: {
                            customer: orders.customers.customers[0] || null,
                            location: orders.customers.customers[1] || null
                        },
                        files: orders.files,
                        finished: orders.order.finished,
                        calendarId: orders.calendar.calendar.id,
                        scenarioId: orders.scenario.scenario.id,
                        countryId: orders.country.countryId,
                        ...extraData
                    })
                })
            );
            let data = await resp.json();
            if (resp.status !== 200) {
                throw new Error(data.message || "Unknown error");
            }
            dispatch(stateChanged(STATE_LOADED));
            dispatch(orderLoadRequested(orders.order.orderId));
            toast("Order saved successfully!", { type: "success" });
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function newOrderWithInitialData(companyId) {
    return async dispatch => {
        try {
            await Promise.all([
                dispatch(push("/dashboard/orders")),
                dispatch(companySelected(0, companyId)),
                dispatch(loadCustomerDetails(0))
            ]); //@todo to fix the problem in this function, sometimes don't have data inside in new order
            await dispatch(companySelected(0, companyId));
            await dispatch(loadCustomerDetails(0));
        } catch (e) {
            toast(e.message, { type: "error" });
            dispatch(errorChanged(e.message));
            dispatch(stateChanged(STATE_ERROR));
        }
    };
}

export function setFinishedAndSave(finished) {
    return async (dispatch, getState) => {
        let finishDate;
        if (finished) {
            finishDate = await dispatch(
                showPromptModal({
                    title: "Do you reallly want to finish order?",
                    requireContent: true,
                    fieldType: "date",
                    fieldLabel: "Finish date",
                    message: "",
                    defaultValue: moment()
                })
            );
            if (!finishDate) {
                return;
            }
        }

        dispatch(finishedChanged(finished));
        dispatch(modalVersionMessageChanged(finished ? "Order finished " + formatDate(finishDate) : "Order reopened"));
        const extraData = {};
        if (finishDate) {
            extraData.finishDate = finishDate;
        }
        dispatch(newOrderVersionCreated(extraData));
    };
}
